import React from "react";
import { graphql } from "gatsby";
import FreeVideo from "../../components/video/freeVideo";

const VideoJson = (props) => {
  const { videoJson } = props.data;

  return (
    <>
      {videoJson.type === "free" && (
        <FreeVideo videoJson={videoJson} withDoc={false} />
      )}
    </>
  );
};

export default VideoJson;

export const query = graphql`
  query ($id: String) {
    videoJson(id: { eq: $id }) {
      id
      category
      created
      description
      no
      slug
      series
      title
      type
    }
  }
`;
